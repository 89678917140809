<template>
    <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgc" />
    <div v-if="isShown" class="w-screen h-screen bottom-0 top-0 left-0 right-0 fixed z-20 inset-0 flex justify-center items-center bg-black bg-opacity-25">
        <div class="fixed right-0 top-0 left-0 bottom-0 -z-10 w-screen h-screen"></div>
        <div class="w-1/2 h-fit max-h-5/6 rounded-md bg-white flex flex-col justify-start items-center overflow-hidden">
            <div class="bg-NeonBlue w-full flex flex-row justify-between items-center p-8 py-4">
                <h1 class="text-xl font-medium w-full text-left text-white">Create a custom question</h1>
                <button class="text-white text-lg font-semibold" @click="closePanel"><font-awesome-icon :icon="['fas', 'times']" /></button>
            </div>
            <select name="" id="" v-model="qstType" class="border border-slate-300 rounded-sm w-11/12 mx-auto my-6 h-10 px-3">
                <option value="" selected>Select a question type</option>
                <option value="Essay">Essay</option>
                <option value="Multiple-choice">Multiple choice</option>
            </select>
            <Transition name="slide">
                <div v-show="qstType == 'Essay'" class="flex bg-white rounded flex-col items-center w-11/12 mx-auto overflow-hidden justify-center">
                    <form @submit.prevent="addCustomQuestion()" class="flex flex-col gap-5 pb-4 items-center justify-center h-full w-full">
                        <div class="flex flex-row justify-between gap-4 items-center w-full">
                            <div class="flex flex-col items-start gap-0 justify-between w-4/5">
                                <label for="qstTitle">Title</label>
                                <input
                                    id="qstTitle"
                                    required
                                    v-model="essay.name"
                                    class="w-full p-2 border border-solid border-gray-300 rounded shadow-md"
                                    type="text"
                                    placeholder="Enter question title..."
                                />
                            </div>
                            <div class="flex flex-col items-start gap-0 justify-between w-1/5">
                                <label for="qstTime">Time (minutes)</label>
                                <input
                                    id="qstTime"
                                    required
                                    v-model="essay.time"
                                    class="w-full p-2 border border-solid border-gray-300 rounded shadow-md"
                                    value="1"
                                    min="1"
                                    max="5"
                                    type="number"
                                    placeholder="Enter essay time..."
                                />
                            </div>
                        </div>

                        <textarea
                            required
                            v-model="essay.question"
                            class="w-full h-36 resize-none p-2 border border-solid border-gray-300 rounded shadow-md"
                            placeholder="Enter essay question..."
                            name=""
                            id=""
                        ></textarea>

                        <div class="flex flex-row items-center justify-end mt-4 gap-4 w-full">
                            <button type="submit" class="w-32 h-10 px-2 rounded bg-NeonBlue font-semibold text-white shadow-md hover:opacity-95">Confirm</button>
                            <button class="w-32 h-10 px-2 rounded bg-red-500 font-semibold text-white shadow-md hover:opacity-95" @click="cancelPanel">Cancel</button>
                        </div>
                    </form>
                </div>
            </Transition>
            <Transition name="slide">
                <div v-show="qstType == 'Multiple-choice'" class="flex bg-white rounded flex-col items-center w-11/12 gap-5 overflow-hidden justify-center">
                    <form @submit.prevent="addCustomQuestion()" class="flex flex-col gap-5 pb-4 items-center justify-center h-full w-full">
                        <div class="flex flex-row justify-between gap-4 items-center w-full">
                            <div class="flex flex-col items-start gap-0 justify-between w-4/5">
                                <label for="qstTitle_">Title</label>
                                <input
                                    id="qstTitle_"
                                    required
                                    v-model="multiple_choice.name"
                                    class="w-full p-2 border border-solid border-gray-300 rounded shadow-md"
                                    type="text"
                                    placeholder="Enter question title..."
                                />
                            </div>
                            <div class="flex flex-col items-start gap-0 justify-between w-1/5">
                                <label for="qstTime_">Time (minutes)</label>
                                <input
                                    id="qstTime_"
                                    required
                                    v-model="multiple_choice.time"
                                    value="1"
                                    min="1"
                                    max="5"
                                    class="w-full p-2 border border-solid border-gray-300 rounded shadow-md"
                                    type="number"
                                    placeholder="Enter Multiple-choice time..."
                                />
                            </div>
                        </div>
                        <textarea
                            required
                            v-model="multiple_choice.question"
                            class="w-full h-36 resize-none p-2 border border-solid border-gray-300 rounded shadow-md"
                            placeholder="Enter Multiple-choice question..."
                            name=""
                            id=""
                        ></textarea>
                        <div class="flex max-h-52 relative overflow-y-auto items-center flex-col gap-2 justify-center w-full">
                            <div v-for="(option, key) in multiple_choice.options" class="flex items-center gap-2 w-full" :key="key">
                                <input
                                    :id="'option_' + key"
                                    :placeholder="`Enter option n°${key}`"
                                    v-model="multiple_choice.options[key]"
                                    class="w-11/12 p-2 border border-solid border-gray-300 rounded shadow-md"
                                    type="text"
                                    required
                                />
                                <button
                                    v-if="shouldRenderDeleteButton(key)"
                                    class="flex justify-center items-center absolute right-7 bottom-0 -translate-y-1/2 border border-gray-300 rounded-full w-6 h-6 cursor-pointer hover:opacity-95"
                                    @click="deleteOption(key)"
                                >
                                    <font-awesome-icon class="bg-white font-normal w-3 h-3" :icon="['fas', 'minus']" />
                                </button>
                            </div>
                            <button class="absolute w-6 h-6 flex justify-center items-center rounded-full bottom-0 border border-gray-300 -translate-y-1/2 right-0" @click="addOption">
                                <font-awesome-icon class="w-3 h-3 font-light" :icon="['fas', 'plus']" />
                            </button>
                        </div>
                        <!-- <div class="w-full flex justify-center">
                            <button class="w-full bg-[#53b9ab] h-10 px-2 rounded font-semibold text-white shadow-md hover:opacity-95" @click="addOption">Add Option</button>
                        </div> -->
                        <div class="flex flex-row mt-4 items-center justify-end gap-4 w-full">
                            <button type="submit" class="w-32 h-10 px-2 rounded bg-NeonBlue font-semibold text-white shadow-md hover:opacity-95">Confirm</button>
                            <button class="w-32 h-10 px-2 rounded bg-red-500 font-semibold text-white shadow-md hover:opacity-95" @click="cancelPanel">Cancel</button>
                        </div>
                    </form>
                </div>
            </Transition>
        </div>
    </div>
</template>
<script>
import axios from "axios";
//import LoaderComponent from './LoaderComponent';
import ToastNotification from "./ToastNotification.vue";
import { useStore } from "../store/index";

export default {
    name: "AddCustomQuestion",
    components: { ToastNotification },
    props: {
        isShown: Array,
        closePanel: Function,
        addQst: Function,
        type: Array,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            qstType: "",
            essay: {
                name: "",
                question: "",
                category: "Essay",
                time: 1,
            },
            multiple_choice: {
                name: "",
                question: "",
                category: "Multiple-choice",
                time: 1,
                options: {
                    1: "", // Initially empty
                    2: "", // Initially empty
                },
            },
        };
    },
    methods: {
        cancelPanel() {
            this.qstType = "";
            this.essay = {
                name: "",
                question: "",
                category: "Essay",
                time: 1,
            };
            this.multiple_choice = {
                name: "",
                question: "",
                category: "Multiple-choice",
                time: 1,
                options: {
                    1: "",
                    2: "",
                },
            };
            this.closePanel();
        },
        addCustomQuestion() {
            let data = {};
            if (this.qstType == "Essay") {
                data = this.essay;
            } else if (this.qstType == "Multiple-choice") {
                data = this.multiple_choice;
            }
            console.log(data);
            axios
                .post("https://server.go-platform.com/CustomQuestion/add", data, { withCredentials: true })
                .then((response) => {
                    console.log("Question created successfully");
                    this.message = "Question created successfully!";
                    this.bgc = "success";
                    this.isVisible = true;
                    this.closePanel();
                    this.Store.allCustomQuestions = [];
                    this.Store.fetchCustomCompany();
                    this.Store.fetchCustomGoPlatform();
                    this.addQst(response.data.newQ);
                })
                .catch((error) => {
                    console.log("Error while creating question", error);
                    this.message = "Creating a question has failed";
                    this.bgc = "error";
                    this.isVisible = true;
                    this.closePanel();
                });
        },

        addOption() {
            const newKey = Object.keys(this.multiple_choice.options).length + 1;
            // Using normal assignment to add a new property
            this.multiple_choice.options[newKey.toString()] = "";
            console.log("here are the options:", this.multiple_choice.options);
        },
        deleteOption(key) {
            // Using delete to remove the property
            delete this.multiple_choice.options[key];
        },
        shouldRenderDeleteButton(key) {
            const keys = Object.keys(this.multiple_choice.options);
            const lastKey = keys[keys.length - 1];
            return key !== "1" && key !== "2" && key == lastKey;
        },
    },
};
</script>
<style scoped lang="scss">
.slide-enter-active {
    transition: all 1s ease;
    transition-delay: 0.2s;
}

.slide-leave-active {
    transition: all 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
    // transform: translateX(-500px);
    opacity: 0;
}

.slide-enter-to,
.slife-leave-from {
    opacity: 1;
    // transform: translateX(0);
}
</style>
