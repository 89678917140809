<template>
    <div class="allAssessmentsWrapper" v-if="showAssessments">
        <div class="allAssessments">
            <table>
                <thead>
                    <th>{{ $t("Assessment") }}</th>
                    <th>{{ $t("Score") }}</th>
                    <th>{{ $t("Time") }}</th>
                    <th>{{ $t("Status") }}</th>
                    <th>{{ $t("Candidates") }}</th>
                </thead>

                <tbody>
                    <tr v-for="archivesAssessmentData in this.Store.companyAssessments" v-bind:key="archivesAssessmentData.id">
                        <td>
                            <span>{{ archivesAssessmentData.jobTitle.charAt(0) }}</span
                            >{{ archivesAssessmentData.title }}
                        </td>
                        <td>
                            <input
                                type="range"
                                :value="archivesAssessmentData.score"
                                disabled
                                id="success-slider"
                                :style="{
                                    background: `linear-gradient(90deg, #2196f3 ${
                                        archivesAssessmentData.min_score ? archivesAssessmentData.min_score : archivesAssessmentData.recommanded_score
                                    }%, #FFF ${archivesAssessmentData.min_score ? archivesAssessmentData.min_score : archivesAssessmentData.recommanded_score}%)`,
                                }"
                            />
                        </td>
                        <td>1h:20min:15s</td>
                        <td>
                            <label class="switch">
                                <input type="checkbox" :checked="archivesAssessmentData.project_status === 'Active' ? true : false" />

                                <!-- <span class="slider round"></span> -->
                                <span
                                    class="slider round"
                                    :class="{
                                        'custom-style': archivesAssessmentData.archived,
                                    }"
                                    v-if="archivesAssessmentData.passed"
                                ></span>
                                <span
                                    class="slider round"
                                    :class="{
                                        'custom-style': archivesAssessmentData.archived,
                                    }"
                                    v-if="!archivesAssessmentData.passed"
                                ></span>
                            </label>
                        </td>
                        <td>12</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="5" @click="toggleAllAssessmentsArchive">
                            {{ $t("Hide") }}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import { useStore } from "../store/index.js";
export default {
    name: "AllArchiveAssessments",
    props: {
        archivesAssessmentsData: Array,
        showAssessments: Boolean,
        toggleAllAssessmentsArchive: Function,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
};
</script>

<style scoped lang="scss">
.allAssessmentsWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #0000003b;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
.allAssessments {
    width: 60%;
    height: fit-content;
    max-width: 80%;
    border-radius: 20px;
    overflow-y: scroll;
}
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 1px;
    bottom: 2px;
    border: 0.5px solid rgba(255, 255, 255, 0.342);
    background-color: #f9fafd;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.custom-style:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    right: 5px;
    bottom: 3px;
    border: 0.5px solid rgba(255, 255, 255, 0.342);
    background-color: #f9fafd;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

#success-slider {
    -webkit-appearance: none;
    appearance: none;
    // background: linear-gradient(90deg, #2196f3 70%, #fff 70%);
    border: 1px solid #2196f3;
    border-radius: 90px;
    margin-right: 48px;
    width: 80%;
    height: 10px;
}

#success-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: -2px;
    background: #2196f3;
    cursor: pointer;
}

table {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border-collapse: collapse;
    thead {
        background-color: #f9fafd;
        & > :first-child {
            text-align: left;
        }
        th {
            padding: 1rem;
            font-family: "Roboto";
            font-weight: 500;
            font-size: 16px;
            color: #4d5969;
        }
    }
    tbody {
        background-color: #fff;
        overflow: scroll;
        tr {
            & > :first-child {
                text-align: left;
                span {
                    color: #2196f3;
                    background-color: #e9e3ff;
                    margin-right: 2%;
                    padding: 4% 5%;
                    border-radius: 50%;
                }
            }
            td {
                padding: 1rem;
                font-family: "Roboto";
                font-weight: 500;
                font-size: 16px;
                color: #4d5969;
                border: none;
            }
        }
    }
    tfoot {
        background-color: #f9fafd;
        tr {
            td {
                padding: 1rem;
                font-family: "Roboto";
                font-weight: 500;
                font-size: 16px;
                color: #4d5969;
                border: none;
            }
        }
    }
}
</style>
