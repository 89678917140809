<template>
    <ToastNotification message="Email copied successfully" :isVisible="isVisible" bgColor="green" />
    <!-- <CandidateActivity :showActivity="showActivity" :toggleActivity="toggleActivity" :candidate="candidate" :candidateInfo="candidateInfo" /> -->
    <!-- <div v-if="showMenu" @click="toggleMenu" class="w-[100%] h-[100%] fixed top-0 left-0 right-0 bottom-0" style="z-index: 10; background: red"></div> -->
    <div v-if="showMenu" @click="toggleMenu" class="absolute top-0 left-0 right-0 bottom-0" style="z-index: 10; background: transparent"></div>
    <div class="relative candidate">
        <CandidateMenu :showMenu="showMenu" :toggleMenu="toggleMenu" :candidateStatus="candidate.status" :candidateId="candidate._id" :getCandidates="getCandidates" />
        <div class="header" :class="['header-background-' + backgroundHeader]">
            <div class="profile">
                <div class="profile-avatar">
                    {{ candidateInfo?.Last_name.charAt(0).toUpperCase() + "." + candidateInfo?.First_name.charAt(0).toUpperCase() }}
                </div>
                <span class="profile-name">{{ candidateInfo?.Last_name + " " + candidateInfo?.First_name }}</span>
                <div>
                    <!-- <button class="nav-btn btn-scale" style="background-color: transparent" @click="toggleMenu" :class="{ hideText: !SidebarViewOpen }"> -->
                    <font-awesome-icon :icon="['fas', 'ellipsis']" class="nav-icon" @click="toggleMenu" style="color: #fff" />
                    <!-- </button> -->
                </div>
                <!-- <img loading="lazy"  decoding="async" class="profile-image" :src="getImgUrl(candidateInfo?.Avatar)" alt="candidate-image" /> -->
            </div>
        </div>
        <div
            class="info"
            @click="
                () => {
                    this.Store.candidateInfoAct = candidateInfo;
                    this.Store.candidateActivity = candidate;
                    this.$router.push({
                        path: '/candidate',
                        query: { email: candidateInfo.Email, projectId: candidate?.projectId },
                    });
                }
            "
        >
            <!-- <span>Info</span> -->
            <div class="info-section">
                <div class="grid-row-info">
                    <span class="label-span">Email</span>
                    <span class="email" @click="copyEmail">{{ candidateInfo?.Email }} <img loading="lazy" decoding="async" src="../assets/Images/icons/copy-icon.svg" alt="copy-icon" /></span>
                </div>
                <div class="grid-row-info">
                    <span class="label-span">{{ $t("Status") }}</span>
                    <span class="status" :class="passed ? 'passed' : 'failed'">{{ $t("Passed on") }} {{ getDateAndTime(candidate.createdAt) }}</span>
                </div>
                <div class="grid-row-info">
                    <span class="label-span">{{ $t("Position") }}</span>
                    <span class="label-span">{{ getProjectName(candidate?.projectId) }}</span>
                </div>
                <div class="grid-row-info">
                    <span class="label-span">{{ $t("Assessment") }}</span>
                    <span class="label-span">Sample Assessment</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import CandidateActivity from "@/views/ActivityView.vue";
// import CandidateActivity from "@/components/CandidateActivity.vue";
import ToastNotification from "./ToastNotification.vue";
import CandidateMenu from "@/components/CandidateMenu.vue";
import { useStore } from "../store/index";
export default {
    name: "CandidateCard",
    components: {
        // CandidateActivity,
        ToastNotification,
        CandidateMenu,
    },
    props: ["candidate", "candidateInfo", "passed", "backgroundHeader", "getCandidates"],
    data() {
        return {
            showActivity: false,
            isVisible: false,
            showMenu: false,
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        copyEmail() {
            const emailText = this.candidateInfo.Email;
            const el = document.createElement("textarea");
            el.value = emailText;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            this.isVisible = true;
            setTimeout(() => {
                this.isVisible = false;
            }, 5000);
        },
        toggleActivity() {
            this.showActivity = !this.showActivity;
        },
        getDateAndTime(dateString) {
            const date = new Date(dateString);

            const year = date.getUTCFullYear();
            const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
            const day = date.getUTCDate().toString().padStart(2, "0");
            const hours = date.getUTCHours().toString().padStart(2, "0");
            const minutes = date.getUTCMinutes().toString().padStart(2, "0");
            const seconds = date.getUTCSeconds().toString().padStart(2, "0");

            const formattedDateTime = `${year}-${month}-${day} ${parseInt(hours) + 1}:${minutes}:${seconds}`;
            return formattedDateTime;
        },
        getImgUrl(imgFileId) {
            if (imgFileId) {
                var image = `data:image/png;base64,${imgFileId}`;
                // console.log({ image });
                return image;
            }
            return require("../assets/Images/candidate-image.png");
        },
        getProjectName(projectId) {
            const project = this.Store.projects.find((project) => project._id === projectId);
            return project?.name;
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
    },
};
</script>

<style scoped lang="scss">
.candidate {
    background: #fff;
    border-radius: 12px;
    padding: 12px;
    padding-right: 20px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    width: 100%;
    // &:hover {
    //     transform: scale(1.02);
    // }
}

.header {
    padding: 8px;
    filter: drop-shadow(0px 18px 40px rgba(112, 144, 176, 0.12));
    border-radius: 17px;
}

.header-background-1 {
    background: linear-gradient(135deg, #868cff 0%, #4318ff 100%, #2196f3 100%);
}
.header-background-2 {
    background: linear-gradient(135deg, #ffd133 0%, #4318ff 100%, #2196f3 100%);
}
.header-background-0 {
    background: linear-gradient(135deg, #00848b 7.08%, #067a96 35.73%, #4318ff 100%, #2196f3 100%);
}

.dots {
    display: block;
}

.profile {
    width: 100%;
    margin-top: -8px;
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    padding-top: 2%;
    gap: 0.5rem;
    flex-direction: row;
}

.profile-avatar {
    width: 68.266px;
    height: 68.266px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1136.629px;
    background: #1485fd;
    color: #fff;
    font-family: Inter;
    font-size: 18.204px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.profile-name {
    width: 75%;
    text-align: left;
    font-weight: 700;
    font-size: 28px;

    color: #fff;
}

.profile-image {
    max-width: 25%;
}

.grid-row-info {
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: center;
    margin-bottom: 16px;
}

.info > span {
    display: block;
    font-weight: 500;
    font-size: 12px;
    color: #a3aed0;
    margin: 24px 0px;
}

.info-section {
    /* margin-left: 24px; */
    margin-top: 1.25rem;
}

.label-span {
    font-weight: 400;
    font-size: 13px;
    color: #343637;
    text-align: left;
}

.email {
    cursor: pointer;
    // z-index: 3;
    display: flex;
    justify-content: start;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    color: #2196f3;
}

.email > img {
    margin-left: 8px;
}

.status {
    width: fit-content;
    padding: 2px 12px;
    font-weight: 400;
    font-size: 13px;
    color: #f7f8f9;
    border-radius: 10px;
}

.passed {
    background: #05cd99;
}

.failed {
    background: #e62e7b;
}
.profile-image {
    border-radius: 50%;
    // width: 150px;
}
.nav-btn {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
